<template>
  <div class="app-download">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center"></div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="step" v-if="mobileType == 'android'">
        <img class="img-step" :src="androidS1" />
        <img class="img-step" :src="androidS2" />
      </div>
      <div class="step" v-else>
        <img class="img-step" :src="iosS1" />
        <img class="img-step" :src="iosS2" />
        <img class="img-step" :src="iosS3" />
      </div>
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import footerMenu from "@/components/footerMenu.vue";
export default {
  components: {
    footerMenu,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      androidS1: require("@/assets/images/xiaomi-1.png"),
      androidS2: require("@/assets/images/xiaomi-2.png"),
      iosS1: require("@/assets/images/ios-1.png"),
      iosS2: require("@/assets/images/ios-2.png"),
      iosS3: require("@/assets/images/ios-3.png"),
      mobileType: "android",
    };
  },
  methods: {
    onGoBack() {
      this.$router.go(-1);
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.type) {
      this.mobileType = this.$route.query.type;
    }
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.app-download {
  display: flex;
  flex-direction: column;
  background: rgb(241, 241, 241);
  height: 100%;
  .header {
    height: 68px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 500;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    .step {
      display: flex;
      flex-direction: column;
      align-items: center;
      .img-step {
        width: 80%;
        margin: 20px;
      }
    }
  }
  .footer {
    flex: 0 0 auto;
  }
}
</style>
